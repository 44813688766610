import _ from '@/utils/lodash'
import { likeRegExp } from '@/offline/database'

export default {
  async _addSubqueriesPlantillaArticulo (Vue, rows) {
    const tables = Vue.$offline.db.tables
    for (let row of rows) {
      const grupos = await Vue.$offline.db
        .select()
        .from(tables.plantilla_grupo_articulo)
        .where(
          Vue.$offline.db.op.and(
            tables.plantilla_grupo_articulo.estado.gt(0),
            tables.plantilla_grupo_articulo.idplantilla_articulo.eq(row.plantilla_articulo.idplantilla_articulo),
          )
        )
        .orderBy(tables.plantilla_grupo_articulo.orden)
        .exec()
      row.grupos = _.map(grupos, 'descripcion')
    }
    return rows
  },
  _selectPlantillaArticuloBase (Vue) {
    const tables = Vue.$offline.db.tables
    const plantillaArticuloRes = Vue.$offline.plantillaArticulo
    return Vue.$offline.db
      .select()
      .from(tables.plantilla_articulo)
      .innerJoin(plantillaArticuloRes.dummyTable, plantillaArticuloRes.pk.eq(plantillaArticuloRes.dummyPk))
  },
  async selectPlantillaArticulo (Vue, filter, search, sorter, page) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.plantilla_articulo.descripcion.match(reQ),
        )
      )
    }
    if (filter.descripcion.value) {
      whereFilter.push(
        Vue.$offline.db.op.and(
          tables.plantilla_articulo.descripcion.match(likeRegExp(filter.descripcion.value))
        )
      )
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.plantilla_articulo.estado.gt(0),
        ...whereSearch,
        ...whereFilter
      )
    )
    const rows = await this._selectPlantillaArticuloBase(Vue)
      .where(...where)
      .limit(Vue.$offline.db.ITEMS_PER_PAGE)
      .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
      .orderBy(tables.plantilla_articulo.orden)
      .exec()
    return [
      await this._addSubqueriesPlantillaArticulo(Vue, rows),
      [],
    ]
  },
  selectPlantillaArticuloRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    return this._selectPlantillaArticuloBase(Vue)
      .where(tables.plantilla_articulo.idplantilla_articulo.in(pks))
      .exec()
  }
}
